import * as React from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// material-ui
import { useTheme } from '@mui/material/styles';
import { TableCell, Button, Typography, Avatar, AvatarGroup, Stack } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import useListing from 'hooks/useListing';
import Listing from 'ui-component/Listing';
import useAuth from 'hooks/useAuth';
import { API_URL } from 'config';
// ==============================|| Weekly Result LIST ||============================== //

const WeeklyResultList = () => {
    const theme = useTheme();
    const listing = useListing(`/api/weekly-results`);
    const { user } = useAuth();
    const [columns] = React.useState([
        { label: <FormattedMessage id="Week" />, id: 'weekName' },
        { label: <FormattedMessage id="Number of Calls" />, id: 'numberOfCall' },
        { label: <FormattedMessage id="Status" />, id: 'status' }
    ]);

    const filter = React.useMemo(
        () => [
            {
                label: 'Status',
                column: 'status',
                type: 'checkbox',
                chip: [
                    { label: 'Active', value: 'Active', defaultValue: true },
                    { label: 'Inactive', value: 'Inactive', defaultValue: false }
                ]
            }
        ],
        []
    );

    return (
        <MainCard
            title={
                <Typography variant="h3">
                    <FormattedMessage id="Weekly Result List" />
                </Typography>
            }
            content={false}
            secondary={
                <Button variant="contained" component={Link} to="/weekly-result/add">
                    <FormattedMessage id="Add Result" />
                </Button>
            }
        >
            {/* table */}

            <Listing
                id="weekly-result"
                listing={listing}
                columns={columns}
                filter={filter}
                status={false}
                viewAction={false}
                editAction={user.hasAccess('WeeklyResult-write')}
                deleteAction={user?.hasAccess('WeeklyResult-all')}
                TableComponent={({ weekName, numberOfCall, status, source }) => (
                    <>
                        <TableCell>{weekName}</TableCell>
                        <TableCell
                            style={{
                                // textOverflow: 'ellipsis',
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                                // display: 'inline-block',
                                // paddingBottom: '28px'
                            }}
                        >
                            <Typography>{numberOfCall}</Typography>
                            {/* <Typography>{callDetails}</Typography> */}
                        </TableCell>
                        <TableCell>{status}</TableCell>
                        {/* <TableCell> */}
                        {/* {assignUserList ? (
                                <AvatarGroup max={1} sx={{ maxWidth: 'max-content' }}>
                                    {assignUserList.map((staff, index) => (
                                        <Avatar
                                            alt={staff?.userDetail?.first_name}
                                            src={`${API_URL}/${staff?.userDetail?.user_avatar}`}
                                            title={staff?.userDetail?.first_name}
                                            key={index}
                                        />
                                    ))}
                                </AvatarGroup>
                            ) : (
                                'Not Assigned'
                            )} */}
                        {/* </TableCell> */}
                    </>
                )}
            />
        </MainCard>
    );
};

export default WeeklyResultList;
