import useMenuItems from 'hooks/useMenuItems';
import { useEffect } from 'react';
import adminPages from './pages';
import clientPages from './clientPages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    adminPages,
    clientPages
};

export default menuItems;
