import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
// routes
import AuthenticationRotes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// landing page routing
const LandingPage = Loadable(lazy(() => import('views/landing')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([{ path: '/', element: <LandingPage /> }, LoginRoutes, AuthenticationRotes, MainRoutes]);
}
