import { globalAction, dispatch } from 'store';
import axios from './axios';

function handleResponse(response) {
    if (response?.message) dispatch(globalAction('SHOW_TOASTER', { status: response.status.toLowerCase(), message: response.message }));

    // if (response?.type === 'validationError') {
    //     dispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
    // }

    return response;
}

export async function get(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params
            })
            .then((res) => {
                resolve(handleResponse(res.data));
            })
            .catch((err) => {
                reject(handleResponse(err));
            });
    });
}

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, data)
            .then((res) => {
                resolve(handleResponse(res.data));
            })
            .catch((err) => {
                reject(handleResponse(err));
            });
    });
}

export async function put(url, data) {
    return new Promise((resolve, reject) => {
        axios
            .put(url, data)
            .then((res) => {
                resolve(handleResponse(res.data));
            })
            .catch((err) => {
                reject(handleResponse(err));
            });
    });
}

export async function patch(url, data) {
    return new Promise((resolve, reject) => {
        axios
            .patch(url, data)
            .then((res) => {
                resolve(handleResponse(res.data));
            })
            .catch((err) => {
                reject(handleResponse(err));
            });
    });
}

export function deleteData(url, data) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${url}/${data}`)
            .then((res) => {
                resolve(handleResponse(res.data));
            })
            .catch((err) => {
                reject(handleResponse(err));
            });
    });
}
