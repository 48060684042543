import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';

// project imports
import Transitions from 'ui-component/extended/Transitions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// assets
import PaidIcon from '@mui/icons-material/Paid';
import useConfig from 'hooks/useConfig';

// ==============================|| LOCALIZATION ||============================== //

const LocalizationSection = () => {
    const { borderRadius, locale, onChangeLocale } = useConfig();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [language, setLanguage] = useState(locale);

    const handleListItemClick = (event, lng) => {
        setLanguage(lng);
        onChangeLocale(lng);
        setOpen(false);
        window.location.reload();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setLanguage(locale);
    }, [locale]);

    // const config = window.localStorage.getItem('berry-config');
    // useEffect(() => {
    //     if (locale !== JSON.parse(config)?.locale) {
    //         window.location.reload();
    //     }
    // }, [locale]);

    return (
        <>
            <Box onClick={handleToggle}>
                {matchesXs ? (
                    <Button
                        color="primary"
                        variant="outlined"
                        endIcon={<KeyboardArrowDownIcon />}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                    >
                        {locale === 'en' ? 'En' : 'Es'}
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<TranslateIcon />}
                        endIcon={<KeyboardArrowDownIcon />}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                    >
                        {locale === 'en' ? 'English' : 'Español'}
                    </Button>
                )}
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                sx={{ zIndex: 100, position: 'fixed !important' }}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 0]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                            <Paper elevation={16}>
                                {open && (
                                    <List
                                        component="nav"
                                        sx={{
                                            width: '100%',
                                            minWidth: 130,
                                            maxWidth: 180,
                                            bgcolor: theme.palette.background.paper,
                                            borderRadius: `${borderRadius}px`,
                                            [theme.breakpoints.down('md')]: {
                                                maxWidth: 180
                                            }
                                        }}
                                    >
                                        <ListItemButton selected={language === 'usd'} onClick={(event) => handleListItemClick(event, 'en')}>
                                            <ListItemText
                                                primary={
                                                    <Grid container>
                                                        <Typography color="textPrimary">English</Typography>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={language === 'euro'}
                                            onClick={(event) => handleListItemClick(event, 'es')}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Grid container>
                                                        <Typography color="textPrimary">Español</Typography>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton>
                                    </List>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default LocalizationSection;
