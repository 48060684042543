import * as React from 'react';
import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
// material-ui
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    FormControlLabel,
    Switch,
    CardContent,
    Grid,
    InputAdornment,
    TextField,
    Tooltip,
    Fab,
    Menu,
    Stack,
    Chip,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconAdjustmentsHorizontal, IconRocket } from '@tabler/icons';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CachedIcon from '@mui/icons-material/Cached';
// assets
import SearchIcon from '@mui/icons-material/Search';
// project imports
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AssignLeadUser from 'ui-component/AssignLeadUser';
import AssignedContactUser from 'ui-component/AssignedContactUser';
import AssignContactListUser from 'ui-component/AssignContactListUser';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Loading from './Loading';

const TableHeading = React.memo(({ columns, changeOrder, orderBy, order, status, action, filterIcon, isSerialNumber }) => (
    <TableHead>
        <TableRow>
            {isSerialNumber && (
                <TableCell>
                    <TableSortLabel onClick={(e) => changeOrder('id')}>#</TableSortLabel>
                </TableCell>
            )}
            {columns.map(({ label, id }) => (
                <TableCell key={id} sortDirection={orderBy === id ? orderBy : false}>
                    <TableSortLabel active={orderBy === id} direction={orderBy === id ? order : 'asc'} onClick={(e) => changeOrder(id)}>
                        {label}
                    </TableSortLabel>
                </TableCell>
            ))}
            {status && (
                <TableCell>
                    <TableSortLabel>
                        <FormattedMessage id="Status" />
                    </TableSortLabel>
                </TableCell>
            )}
            {action && (
                <TableCell align="center">
                    <FormattedMessage id="Action" />
                </TableCell>
            )}
        </TableRow>
    </TableHead>
));

const Filter = React.memo(({ filter, filterIcon, listing }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateFilter = (type, column, chipValue, newValue) => {
        const newFilter = [...filter];
        const filterIndex = newFilter.findIndex((ele) => ele.column === column);

        switch (type) {
            case 'checkbox': {
                const chipIndex = newFilter[filterIndex].chip.findIndex((ele) => ele.value === chipValue);
                newFilter[filterIndex].chip[chipIndex].defaultValue = newValue;

                listing.setStateFilter(newFilter);
                break;
            }
            default:
                listing.setStateFilter(newFilter);
                break;
        }

        setAnchorEl(null);
    };

    return (
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            {filterIcon && (
                <Tooltip title="Filter" onClick={handleClick}>
                    <Fab color="primary" size="small" sx={{ boxShadow: 'none', ml: 1, width: 32, height: 32, minHeight: 32 }}>
                        <FilterAltOutlinedIcon fontSize="small" />
                    </Fab>
                </Tooltip>
            )}
            <Menu
                id="menu-user-details-card"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {filter &&
                    filter.map((fData) => (
                        <div key={fData.column}>
                            <Typography variant="subtitle1" sx={{ p: 1, pb: 0 }}>
                                {fData.label}
                            </Typography>

                            {fData.type === 'checkbox' && (
                                <Stack direction="row" spacing={1} sx={{ p: 1 }}>
                                    {fData.chip.map((chipData) => (
                                        <Chip
                                            label={chipData.label}
                                            style={
                                                chipData.defaultValue
                                                    ? {
                                                          color: theme.palette.primary.dark,
                                                          backgroundColor: theme.palette.primary.light
                                                      }
                                                    : {
                                                          color: theme.palette.grey.dark,
                                                          backgroundColor: theme.palette.grey.light
                                                      }
                                            }
                                            onClick={() => updateFilter(fData.type, fData.column, chipData.value, !chipData.defaultValue)}
                                        />
                                    ))}
                                </Stack>
                            )}
                        </div>
                    ))}
            </Menu>
        </Grid>
    );
});

const Listing = ({
    id,
    TableComponent,
    listing,
    additionalData,
    columns,
    filter,
    status = true,
    action = true,
    filterIcon = true,
    CustomFilter = false,
    businessFilter = false,
    editAction = true,
    viewAction = true,
    deleteAction = true,
    CustomAction = false,
    isSerialNumber = true
}) => {
    const serialNo = React.useCallback((index) =>
        listing.orderBy === 'id' && listing.order === 'asc'
            ? listing.total - listing.recordPerPage * (listing.currentPage - 1) - index
            : index + listing.recordPerPage * (listing.currentPage - 1) + 1
    );

    const colSpan = useMemo(() => {
        if (!status && !action) return columns.length + 1;
        if (!status || !action) return columns.length + 2;
        return columns.length + 3;
    }, []);
    const getLeadDetail = () => {};
    const intl = useIntl();
    const customTableBody = (arr, Component) =>
        arr.length > 0 ? (
            arr.map((data, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {isSerialNumber && <TableCell>{serialNo(index)}</TableCell>}
                    {Component(data, additionalData)}
                    {status && (
                        <TableCell>
                            <FormControlLabel
                                control={<Switch checked={data.status === 'Active'} onChange={(e) => listing.changeStatus(e, data.id)} />}
                            />
                        </TableCell>
                    )}
                    {action && (
                        <TableCell align="center" style={{ display: 'flex', justifyContent: 'center' }}>
                            {CustomAction && CustomAction(data, additionalData)}
                            {editAction && (
                                <IconButton
                                    size="large"
                                    color="primary"
                                    onClick={(e) => listing.setCurrentData(data)}
                                    component={Link}
                                    to={`/${id}/${data.id}/edit`}
                                    title={intl.formatMessage({ id: 'Edit' })}
                                >
                                    <EditTwoToneIcon />
                                </IconButton>
                            )}

                            {viewAction && (
                                <IconButton
                                    size="large"
                                    color="primary"
                                    component={Link}
                                    to={`/${id}/${data.id}`}
                                    title={intl.formatMessage({ id: 'view' })}
                                >
                                    <IconRocket />
                                </IconButton>
                            )}

                            {deleteAction && (
                                <IconButton
                                    size="large"
                                    color="error"
                                    onClick={(e) => listing.deleteRow(e, data.id)}
                                    title={intl.formatMessage({ id: 'Delete' })}
                                >
                                    <DeleteTwoToneIcon />
                                </IconButton>
                            )}
                        </TableCell>
                    )}
                </TableRow>
            ))
        ) : (
            <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                    <FormattedMessage id="No Record Found!" />
                </TableCell>
            </TableRow>
        );

    return (
        <>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid container spacing={2} display="flex">
                        <Grid item xs={12} sm={4} display="flex">
                            <Stack direction="row" alignItems="center" spacing={1} width="100%">
                                <TextField
                                    fullWidth
                                    size="small"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon fontSize="small" />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(e) => listing.setSearchStr(e.target.value)}
                                    placeholder={intl.formatMessage({ id: 'Search here' })}
                                    value={listing?.searchStr}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={4} display="flex">
                            <Stack direction="row" alignItems="center" spacing={1} width="100%">
                                {CustomFilter}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={4} display="flex">
                            <Stack direction="row" alignItems="center" spacing={1} width="100%">
                                {businessFilter}
                            </Stack>
                        </Grid>
                    </Grid>

                    {listing?.stateFilter.length > 0 ? (
                        <Filter listing={listing} filter={listing.stateFilter} filterIcon={filterIcon} />
                    ) : (
                        <Filter listing={listing} filter={filter} filterIcon={filterIcon} />
                    )}
                </Grid>
            </CardContent>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeading
                        listing={listing}
                        columns={columns}
                        changeOrder={listing.changeOrder}
                        order={listing.order}
                        orderBy={listing.orderBy}
                        status={status}
                        action={action}
                        filterIcon={filterIcon}
                        isSerialNumber={isSerialNumber}
                    />
                    <TableBody>
                        {listing.isLoading ? (
                            <Loading colSpan={colSpan} />
                        ) : (
                            customTableBody(listing.listingData, (data) => TableComponent(data))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={listing.total}
                rowsPerPage={listing.recordPerPage}
                page={listing.currentPage - 1}
                onPageChange={listing.setpage}
                onRowsPerPageChange={listing.rowsPerPage}
            />
        </>
    );
};

export default Listing;
