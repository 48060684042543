import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams, Link, useOutletContext } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Typography, Divider, Stack, Avatar, AvatarGroup, IconButton, Popover, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { deleteData, get, post, put } from 'utils/axiosRequests';
import { API_URL } from 'config';
import { FormattedMessage, useIntl } from 'react-intl';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import CloseIcon from '@mui/icons-material/Close';
// ==============================|| social-lead Assign/un-assign ||============================== //

const AssignSocialUser = ({ rowId, assignedUserList, allUsers, getLeadDetail, existingStaffList }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { socialLeadID } = useParams();
    const intl = useIntl();
    // const { leadDetails, getLeadDetail } = useOutletContext();
    const formatDate = (data) => moment(data).format('DD-MM-YYYY');

    const [allStaffList, setAllStaffList] = React.useState([]);
    const [staffs, setStaffs] = React.useState(assignedUserList);
    const [searchedValue, setSearchedValue] = React.useState('');
    const selectedStaffList = Array.isArray(staffs)
        ? allStaffList.filter((staffList) => !staffs?.some((selected) => selected && staffList.id === selected.assignStaff))
        : allStaffList;

    useEffect(() => {
        setStaffs(assignedUserList);
    }, [assignedUserList]);

    useEffect(() => {
        if (existingStaffList) {
            setAllStaffList(existingStaffList);
        } else {
            get(`/api/staffs?search=${searchedValue}`).then((res) => {
                if (res?.status === 'success') {
                    setAllStaffList(res?.data);
                }
            });
        }
    }, [searchedValue, existingStaffList]);

    const handleAssignStaff = (id) => {
        if (id) {
            post(`/api/social-media-lead-capture/assign-to-staff/${socialLeadID || rowId}`, {
                assignStaff: Number(id)
            }).then((res) => {
                if (res.status === 'success') {
                    setStaffs((prev) => [...prev, res?.data]);
                    getLeadDetail();
                }
            });
        }
    };

    const [anchorElAddAssign, setAnchorElAddAssign] = React.useState(null);
    const handleOpenAssign = (event) => {
        setAnchorElAddAssign(event.currentTarget);
    };

    // useEffect(() => {
    //     if (!searchedValue) return;
    //     setStaffs(
    //         allUsers.map((user) => {
    //             const reg = new RegExp(searchedValue, 'ig');

    //             if (user?.first_name.match(reg) || user?.last_name.match(reg)) {
    //                 console.log('dffg');
    //             }
    //         })
    //     );
    // }, [searchedValue]);

    const handleCloseAssign = () => {
        setAnchorElAddAssign(null);
    };

    const openAddAssign = Boolean(anchorElAddAssign);
    const handleUnassignStaff = (id) => {
        if (id) {
            deleteData('api/social-media-lead-capture/un-assign-to-staff', id).then((res) => {
                if (res.status === 'success') {
                    setStaffs((prev) => prev.filter((staff) => staff.id !== id));
                    getLeadDetail();
                }
            });
        }
        const confirmed = window.confirm('Are you sure you want to unassign this staff?');

        if (confirmed) {
            console.log('Unassigning staff with id:', id);
        } else {
            console.log('Cancelled unassigning staff');
        }
    };

    return (
        <>
            <Grid container direction="row">
                <Stack flexDirection="row" flexWrap="nowrap">
                    <AvatarGroup max={2} sx={{ flexDirection: 'row-reverse' }}>
                        {staffs &&
                            Array.isArray(staffs) &&
                            staffs.map((staff, index) => (
                                <Stack key={index} direction="row" flexWrap="nowrap" alignItems="center">
                                    <Avatar
                                        alt={staff?.userDetail?.first_name}
                                        src={`${API_URL}/${staff?.userDetail?.user_avatar}`}
                                        title={staff?.userDetail?.first_name}
                                    />
                                </Stack>
                            ))}
                    </AvatarGroup>
                    <IconButton
                        color="primary"
                        // sx={{ border: `1px solid ${theme.palette.grey[300]}` }}

                        onClick={(e) => {
                            handleOpenAssign(e);
                        }}
                    >
                        {' '}
                        <PermIdentityIcon />
                    </IconButton>
                </Stack>
            </Grid>
            <Popover
                // id={id}
                open={openAddAssign}
                anchorEl={anchorElAddAssign}
                onClose={handleCloseAssign}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Grid container spacing={0.5} sx={{ p: 1, width: '100%', maxWidth: 320 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {' '}
                            <FormattedMessage id="Assign Staff" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1 }}>
                        <AvatarGroup max={4} sx={{ flexDirection: 'row' }}>
                            {staffs &&
                                Array.isArray(staffs) &&
                                staffs.map((staff, index) => (
                                    <Stack alignItems="center" ml={1} key={index}>
                                        <Avatar
                                            alt="Assign Staff"
                                            src={`${API_URL}/${staff?.userDetail?.user_avatar}`}
                                            title={staff?.userDetail?.first_name}
                                            sx={{ width: 50, height: 50 }}
                                        />
                                        <Typography variant="caption" fontSize={11}>
                                            {staff?.userDetail?.first_name}
                                        </Typography>
                                        <IconButton
                                            onClick={() => handleUnassignStaff(staff?.id)}
                                            sx={{ position: 'absolute', paddingLeft: '36px', marginTop: '0px', top: '20px' }}
                                        >
                                            <CloseIcon
                                                sx={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    height: '15px',
                                                    width: '15px',
                                                    borderRadius: '50%'
                                                }}
                                            />
                                        </IconButton>
                                    </Stack>
                                ))}
                        </AvatarGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="search-staff"
                            id="search-staff"
                            value={searchedValue}
                            onChange={(e) => {
                                setSearchedValue(e.target.value);
                            }}
                            placeholder={intl.formatMessage({ id: 'search staff' })}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {selectedStaffList &&
                            selectedStaffList.map((staffDetails) => (
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ my: 0.6, cursor: 'pointer' }}
                                    onClick={() => {
                                        handleAssignStaff(staffDetails?.id);
                                    }}
                                >
                                    <Avatar alt="Assign Staff" src={`${API_URL}/${staffDetails?.user_avatar}`} />
                                    <Stack>
                                        <Typography variant="body" lineHeight={1.2}>
                                            {staffDetails?.first_name} {staffDetails?.last_name}
                                        </Typography>
                                        <Typography variant="caption">{staffDetails?.email_address}</Typography>
                                    </Stack>
                                </Stack>
                            ))}
                    </Grid>
                </Grid>
            </Popover>
        </>
    );
};

export default AssignSocialUser;
